<template lang="pug">
  div
    v-row
      v-col(cols="3")
        v-card
          v-container
            v-toolbar(
              dark
              dense
              color="green"
              flat
            )
              span Project Code Form
            create-project-code
      v-col(cols="9")
        v-card
          v-container
            v-toolbar(
              dark
              dense
              flat
              color="green"
            )
              span Project Codes
            table-project-code
</template>
<script>
export default {
  name: 'ProjectCodes',
  components: {
    createProjectCode: () => import('./Create'),
    tableProjectCode: () => import('./Table'),
  },
}
</script>